import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Cat2CustomStore} from "@cat2/legacy-meta-cat/lib/data-grid/shared/custom-store";
import {
  ClientProcessingService,
  CreateConnectedStoreWithLiveData,
  MakeDateProperty,
  MakeNumberProperty,
  MakeSelectProperty,
  MakeStringProperty,
  ModelMetadataBuilder
} from "@cat2/legacy-meta-cat";
import {HaulingQueueClientService} from "../../core/services/hauling-queue-client.service";
import {DatePickerService} from "../../core/services/date-picker.service";
import {Lines, LinesClient} from "../../core/services/live-receiving.swagger";
import {ModelMetadata} from "@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata";
import {SelectOption} from "@cat2/legacy-meta-cat/lib/shared/metadata/select-option";
import {Subscription} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {PaginatorService} from "../../core/services/paginator.service";

@Component({
  selector: 'app-hauling-queue-grid',
  templateUrl: './hauling-queue-grid.component.html',
  styleUrls: ['./hauling-queue-grid.component.scss']
})
export class HaulingQueueGridComponent implements OnInit {
  metadata?: ModelMetadata;
  dataSource?: Cat2CustomStore;

  pageSize: number = 10;
  totalItems: number = 0;
  paginatorSub: Subscription | null = null;

  @ViewChild(MatPaginator) paginator?: MatPaginator;

  constructor(
    private _client: HaulingQueueClientService,
    private _process: ClientProcessingService,
    private datePickerService: DatePickerService,
    private linesClient: LinesClient,
    private changeDetectorRef: ChangeDetectorRef,
    public paginationService: PaginatorService,
  ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.linesClient.getKillLines().subscribe((res) => this.loadMetadata(res.data));
      this.datePickerService.currentScheduleDate.subscribe(() => this.loadDataSource());
      this.changeDetectorRef.detectChanges();
    }, 1000);

    this.paginatorSub = this.paginationService.updatedValues.subscribe(toUpdate => {
      if (toUpdate) {
        if (this.paginator) {
          this.paginator.length = this.paginationService.totalItems;
        }
      }
    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number; }) {
    this.paginationService.currentPage = event.pageIndex;
    this.paginationService.pageSize = event.pageSize;
    this.loadDataSource();
  }

  private isLineCompleted(line: string | number) {
    return this.dataSource?.items
      .filter(i => i?.line.toString() === line.toString())
      .every(i => i?.status.toLowerCase() === 'complete') ?? false;
  }

  onDelete(id: string) {
    this.dataSource?.remove(id).then(() => this.loadDataSource());
  }

  updateDataSource(dataChanged: any) {
    this.dataSource?.update(dataChanged.__id, dataChanged).then(() => this.loadDataSource());
  }

  loadDataSource() {
    setTimeout(() => this.dataSource = CreateConnectedStoreWithLiveData(
      this._client,
      this._process
    ), 1);
  }

  private loadMetadata(lines: Lines[]) {
    const linesOptions: SelectOption[] = lines.map(line => {
      return {display: line.lineName ?? line.line.toString(), value: line.line};
    });
    this.metadata = new ModelMetadataBuilder()
      // properties
      .addProperties([
        MakeStringProperty('__id', 'guid'),
        MakeStringProperty('processLotId', 'Process Lot ID'),
        MakeStringProperty('status', 'Status'),
        MakeStringProperty('growerLot', 'Grower Lot ID'),
        MakeStringProperty('id', 'Delivery ID'),
        MakeStringProperty('ticketNumber', 'Ticket ID'),
        MakeSelectProperty('line', 'Line', linesOptions),
        MakeNumberProperty('catchCount', 'Catch Count'),
        MakeNumberProperty('doaCount', 'DOA Count'),
        MakeNumberProperty('officialCount', 'Official Count'),
        MakeNumberProperty('hangCount', 'Hang Count'),
        MakeDateProperty('processingStarted', 'Start Time', "datetime-local"),
        MakeDateProperty('processingFinished', 'End  Time', "datetime-local"),
        MakeNumberProperty('processingSequence', 'Sequence'),
        MakeStringProperty('parentLotId', 'Parent Lot ID'), // not found
      ])
      // grid configuration
      .setGridAddMode('none')
      .setGridEditMode('cell')
      .addGridTitle('Hauling Queue')
      .disableGridDeleteRow(true)
      .addGridFooterSpace()
      .addGridColumn('processLotId').makeFieldReadOnly('processLotId')
      .addGridColumn('status').makeFieldReadOnly('status')
      .addGridColumn('growerLot').makeFieldReadOnly('growerLot')
      .addGridColumn('id').makeFieldReadOnly('id')
      .addGridColumn('ticketNumber').makeFieldReadOnly('ticketNumber')
      .addGridColumn('line')
      .addGridColumn('catchCount')
      .addGridColumn('doaCount')
      .addGridColumn('officialCount').makeFieldReadOnly('officialCount')
      .addGridColumn('hangCount')
      .addGridColumn('processingStarted')
      .addGridColumn('processingFinished')
      .addGridColumn('processingSequence').makeFieldReadOnly('processingSequence')
      .addGridColumn('parentLotId').makeFieldReadOnly('parentLotId')
      .makeGridFieldDisabledWhen('line', (i: any) => i.data.status.toLowerCase() === 'complete')
      .makeGridFieldDisabledWhen('processingStarted', (i: any) => this.isLineCompleted(i.data.line))
      .makeGridFieldDisabledWhen('processingFinished', (i: any) => this.isLineCompleted(i.data.line))
      .makeGridPaginated(false)
      .setGridAutoWidth(true)
      .create();
  }
}
