import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FlyoutService} from "../../core/services/flyout.service";
import {AppStateService, Cat2NotificationService} from "@cat2/legacy-meta-cat";
import {Subscription, timer} from "rxjs";
import {DateTimeService} from "../../core/services/date-time.service";
import {
  Downtime,
  DowntimeClient,
  Lines,
  LinesClient,
  LiveReceivingProcessLot,
  PayloadOfListOfLines,
  PayloadOfListOfLiveReceivingProcessLot,
  PayloadOfListOfShift,
  PayloadOfLiveReceivingProcessLot,
  PayloadOfPageOfDowntime,
  ProcessLotsClient,
  Shift
} from "../../core/services/live-receiving.swagger";
import {TimeRange} from "../../core/interfaces/time-range";
import {TimelineService} from "../../core/services/timeline.service";
import {HeightService} from "../../core/services/height.service";
import {DatePickerService} from "../../core/services/date-picker.service";
import {map, share} from "rxjs/operators";
import {DragAndDropService} from "../../core/services/drag-and-drop.service";
import {MergeService} from "../../core/services/merge.service";
import {ProcessLotService} from "../../core/services/process-lot.service";
import {FilterService} from "../../core/services/filter.service";
import {ScrollService} from "../../core/services/scroll.service";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {CurrentTimeMarkerComponent} from "../current-time-marker/current-time-marker.component";
import {ProcessLotMqttUpdateService} from "../../core/services/process-lot-mqtt.service";
import * as lodash from 'lodash';
import {compareLines} from "../../core/helpers/compare-lines";
import {GetDepartmentGQL} from "../../graphql/graphql";
import {DateTime, Duration} from "luxon";

@Component({
  selector: 'app-day-timeline',
  templateUrl: './day-timeline.component.html',
  styleUrls: ['./day-timeline.component.scss'],
})

export class DayTimelineComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @ViewChild(CdkVirtualScrollViewport) virtualScrollViewport: CdkVirtualScrollViewport | undefined;
  @ViewChild(CurrentTimeMarkerComponent) currentTimeMarker: CurrentTimeMarkerComponent | undefined;
  @Input() urlDate!: string | null;

  get timeHeight(): number {
    return this.timelineService.timeHeight;
  }

  set timeHeight(value: number) {
    this.timelineService.timeHeight = value;
  }

  startDate!: Date;
  endDate!: Date;
  processLots!: LiveReceivingProcessLot[];
  lines!: Lines[];
  shifts!: Shift[];
  private currentTimeSubscription: Subscription | undefined;
  currentTime!: Date;
  currentDate = this.dateTimeService.getCurrentDate();
  timeRange!: TimeRange[];
  timeLineHeight!: number;
  downtimeEvents!: Downtime[];
  connectedListIds: string[] = [];

  isJoining!: boolean;

  currentScheduleDate!: Date;


  constructor(
    private flyoutService: FlyoutService,
    private processLotsClient: ProcessLotsClient,
    public appStateService: AppStateService,
    private linesClient: LinesClient,
    private dateTimeService: DateTimeService,
    public timelineService: TimelineService,
    private heightService: HeightService,
    private schedulerStateService: DatePickerService,
    public dragAndDropService: DragAndDropService,
    private cat2NotificationService: Cat2NotificationService,
    private downtimeClient: DowntimeClient,
    private mergeService: MergeService,
    public processLotService: ProcessLotService,
    private filterService: FilterService,
    private scrollService: ScrollService,
    private _lotMqtt: ProcessLotMqttUpdateService,
    private getDepartmentGQL: GetDepartmentGQL
  ) {
    this.timeHeight = this.timelineService.timeHeight;
    this.currentTime = new Date();
  }

  ngOnInit() {
    this.currentScheduleDate = this.schedulerStateService.currentScheduleDate.value;
    this.getShifts();
    this.getLines();
    this.getDowntimeEvents();
    this.setCurrentTimeAndUpdate();
    this.getProcessLots();
  }

  ngAfterViewInit(): void {
    this.mergeService.mergeSubject$.subscribe((value: boolean) => this.isJoining = value);
  }

  ngOnChanges() {
    this.processLots = this.processLotService.processLotSubject$.value;
  }

  dropListDropped(event: any) {
    this.appStateService.showLoadingOverlay();
    this.dragAndDropService.dropListDropped(event).then(() => {
      this.appStateService.hideLoadingOverlay();
      this.getProcessLots();
    });
  }

  ngOnDestroy() {
    if (this.currentTimeSubscription) {
      this.currentTimeSubscription.unsubscribe();
    }
  }

  /**
   * Get Process Lots
   */
  getProcessLots(): void {
    this.appStateService.showLoadingOverlay();
    this.processLotsClient.getProcessLotsForDate(this.schedulerStateService.currentScheduleDate.value)
      .subscribe({
        next: (response: PayloadOfListOfLiveReceivingProcessLot) => {
          //this.dateTimeService.convertStringIsoToDateInObj(response.data);
          this.processLotService.processLotSubject$.next(response.data);
          this.processLots = this.processLotService.processLotSubject$.value;
          if (this.processLots.length > 0) {
            let min = this.processLots.reduce((prev, curr) => prev.catchCount < curr.catchCount ? prev : curr);
            let lengthInMinutes = this.dateTimeService.getDuration(min.processingStarted, min.processingFinished).valueOf() / 1000 / 60;
            this.timelineService.timeHeightSubject$.next((80 / lengthInMinutes) * 60);
            this.timelineService.timeHeight = (80 / lengthInMinutes) * 60;
          }
          this._lotMqtt.topicReceived.subscribe(async (topic) => {
            let lot = this.processLots?.find((processLot) =>
              topic?.path?.includes(processLot.processLotId!)
            );
            if (lot) {
              if (topic?.id == 'status') {
                lot.status = topic.payload;
                this.processLotsClient.getLotById(lot.processLotId!)
                  .subscribe(
                    (response: PayloadOfLiveReceivingProcessLot) => {
                      let newInfo = response.data;
                      this.updateLot(newInfo);
                    }
                  );
              } else if (topic.id == 'HANG') {
                lot.hangCount = <number>topic.payload;
              } else if (topic.id == 'DOA') {
                lot.doaValue = <number>topic.payload;
              }
            }
          });
          if (this.processLots.length == 0)
            this.cat2NotificationService.info("No Process Lots are available on current manufacture date")
          //Scroll to current time once
          setTimeout(() => {
            if (this.virtualScrollViewport) {
              this.scrollService.scrollToCurrentTime(
                this.currentTime,
                this.virtualScrollViewport,
                this.timeRange
              )
              this.scrollService.setCdkVirtualScrollViewport(this.virtualScrollViewport);
              this.scrollService.setSharedTimeRange(this.timeRange);

            }
          });
          this.appStateService.hideLoadingOverlay();
        },
        error: (error) => this.cat2NotificationService.error(error)
      });
  }

  /**
   * Given a process lot model, update it in the collection if it exists. If not, insert it into the lot array.
   * @param processLot
   */
  updateLot(processLot: LiveReceivingProcessLot) {
    processLot.processingStarted = new Date(processLot.processingStarted);
    processLot.processingFinished = new Date(processLot.processingFinished);
    const lot = this.processLots.find(
      (responseItem) => responseItem.guid == processLot.guid
    );
    if (lot) lodash.assign(lot, processLot);
    else this.processLots.push(processLot);
  }

  /**
   * Get Lines
   */
  getLines(): void {
    this.appStateService.showLoadingOverlay();
    this.linesClient.getKillLines().subscribe({
      next: (response: PayloadOfListOfLines) => {
        this.lines = response.data.sort((a, b) => compareLines(a, b));
        this.appStateService.hideLoadingOverlay();
        this.connectedListIds = this.lines.map((line: Lines) => line.line.toString());
      },
      error: (error) => this.cat2NotificationService.error(error)
    });
  }

  /**
   * Get downtime events
   */
  getDowntimeEvents(): void {
    this.appStateService.showLoadingOverlay();
    this.downtimeClient.forManufactureDate(this.schedulerStateService.currentScheduleDate.value, 0, 999).subscribe({
      next: (response: PayloadOfPageOfDowntime) => {
        this.downtimeEvents = response.data.items!;
        this.appStateService.hideLoadingOverlay();
      },
      error: (error) => this.cat2NotificationService.error(error)
    });
  }

  /**
   * Get shifts
   */
  getShifts(): void {
    this.appStateService.showLoadingOverlay();
    if (this.urlDate) {
      this.schedulerStateService.setDateFromURL(this.urlDate);
    }
    let date = new Date(this.schedulerStateService.currentScheduleDate.value.getFullYear(),
      this.schedulerStateService.currentScheduleDate.value.getMonth(),
      this.schedulerStateService.currentScheduleDate.value.getDate()
    );
    this.linesClient.getShifts(date).subscribe({
      next: (response: PayloadOfListOfShift) => {
        //this.dateTimeService.convertStringIsoToDateInObj(response.data);
        this.shifts = response.data;
        this.getExclusions();
        this.dragAndDropService.shifts = response.data;
        this.setInitialTimelineStartAndEnd();
        this.timeLineHeight = this.heightService.getRangeHeightInPixels(this.startDate, this.endDate);
        this.setTimeRange();
        this.appStateService.hideLoadingOverlay()
      },
      error: (error) => this.cat2NotificationService.error(error)
    });
  }

  /**
   * Set Time Range
   * @description should be set after this.startDate and this.endDate are set
   */
  setTimeRange() {
    this.timeRange = this.timelineService.createTimeRange(this.startDate, this.endDate);
  }

  /**
   * Set Initial Timeline Start and End Date
   */
  setInitialTimelineStartAndEnd(): void {
    let date = new Date(this.shifts[0].manufactureDateStartDateTime);
    let endShiftDate = new Date(this.shifts[0].manufactureDateEndDateTime);
    this.startDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours() - 1
    );
    this.endDate = new Date(
      endShiftDate.getFullYear(),
      endShiftDate.getMonth(),
      endShiftDate.getDate(),
      endShiftDate.getHours() + 1
    );
  }

  /**
   * Open Flyout
   */
  openFlyout(processLotId: string) {
    this.flyoutService.processLotId = processLotId;
    this.flyoutService.open();
  }

  /**
   * Set Current Time
   */
  setCurrentTimeAndUpdate() {
    this.currentTimeSubscription = timer(0, 100).pipe(
      map(() => new Date()),
      share()
    ).subscribe(
      (time: Date) => {
        this.currentTime = time;
      }
    );
  }

  //#region Merge Lots

  onClick(processLot: LiveReceivingProcessLot): void {
    if (!this.isJoining)
      this.openFlyout(processLot?.processLotId!);
  }

  isFiltered(processLot: LiveReceivingProcessLot): boolean {
    return this.filterService.checkFiltered(processLot);
  }

  canDrag(status?: string) {
    return status == 'SCHEDULED' || status == 'WAITING';
  }

  getConnectedLines(line: Lines): string[] {
    return this.connectedListIds.filter(
      (connectedLine) => connectedLine !== line.line.toString()
    );
  }


  exclusions?: { start: Date, end: Date }[];

  getExclusions() {
    this.getDepartmentGQL.watch().valueChanges
      .pipe(map(response => response.data.department?.spans.filter(span => span.classification === "EXCLUSION")))
      .subscribe(exclusionSpans => {
        if (!exclusionSpans)
          return;

        // get day start time
        const dayStartDT = DateTime.fromJSDate(this.shifts[0].manufactureDateStartDateTime);

        // get downtime start and end
        this.exclusions = exclusionSpans
          .map(span => ({
            start: dayStartDT.plus(Duration.fromISO(span.startOffset)).toJSDate(),
            end: dayStartDT.plus(Duration.fromISO(span.startOffset)).plus(Duration.fromISO(span.duration)).toJSDate(),
          }));
      });
  }
}
