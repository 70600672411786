import {Component, OnInit} from '@angular/core';
import {TimelineService} from "../../../core/services/timeline.service";

const ZOOM_KEY = 'zoomValue';

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.css']
})
export class ZoomComponent implements OnInit {
  minHeight = 50;
  maxHeight = 500;
  stepSize = 10;

  constructor(
    private _timelineService: TimelineService,
  ) {
  }

  ngOnInit(): void {
    this.timelineService.timeHeightSubject$.subscribe(
      (value) => {
        this.minHeight=value/2;
        this.maxHeight=value*2;
      }
    );
    const savedZoomValue = localStorage.getItem(ZOOM_KEY);
    if (savedZoomValue) {
      this.handleZoom(+savedZoomValue);
    }
  }

  get timelineService() {
    return this._timelineService;
  }

  /**
   * Zoom out to min height
   */
  zoomOut() {
    this.handleZoom(this.minHeight);
  }

  /**
   * Zoom in to max height
   */
  zoomIn() {
    this.handleZoom(this.maxHeight);
  }

  /**
   * Set time Height and render items again
   * @param value
   */
  handleZoom(value: number | null) {
    if (value) {
      this._timelineService.timeHeight = value;
      localStorage.setItem(ZOOM_KEY, value.toString());
    }
  }
}
